<template>
  <div>
    <!-- notification area -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="submitted = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">Successfully Submitted</div>
    </v-alert>

    <!-- laoding screen -->
    <v-row v-if="componentLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <!-- header text -->
          <v-row>
            <v-col>
              <h1>
                <span v-if="component_type == 'edit'"
                  >Edit Deal Information</span
                >
                <span v-if="component_type == 'add'">Add Deal</span>
                <span v-if="component_type == 'renew'">Renew Deal</span>
              </h1>
              <p
                v-if="component_type == 'edit'"
                class="text-subtitile-1 grey--text"
              >
                {{ dealData.public_name }}
              </p>
            </v-col>
          </v-row>

          <!-- <p class="py-4">
            This is where we are going to add the deal. Deals are a critical
            component in getting us those sweet sweet fees. Simply add their
            name, type, and areas they lend in and BOOM! Retirement.
          </p> -->

          <!-- form -->
          <v-form ref="form" v-model="formRules.valid">
            <!-- internal name -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="dealData.internal_name"
                  label="Deal Internal Name (Only visible to us)"
                  :rules="formRules.required"
                />
              </v-col>
            </v-row>

            <!-- internal description -->
            <v-row>
              <v-col>
                <v-textarea
                  v-model="dealData.description"
                  label="Internal Description"
                  auto-grow
                  outlined
                  :rules="formRules.required"
                />
              </v-col>
            </v-row>

            <!-- province -->
            <v-row>
              <v-col>
                <v-select
                  :items="deal_province_options"
                  v-model="dealData.province"
                  label="Province"
                  :rules="formRules.required"
                />
              </v-col>
            </v-row>

            <!-- deal purpose -->
            <v-row>
              <v-col>
                <v-select
                  :items="deal_purpose_options"
                  v-model="dealData.deal_purpose"
                  label="Deal Purpose"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>

            <!-- deal type -->
            <v-row>
              <v-col>
                <v-select
                  :items="deal_type_options"
                  v-model="dealData.deal_type"
                  label="Deal Type"
                  :rules="formRules.required"
                ></v-select>
              </v-col>
            </v-row>

            <!-- deal stage -->
            <v-row>
              <v-col>
                <v-select
                  :items="kanban_stage_options"
                  v-model="dealData.kbn_stg.id"
                  label="Deal Stage"
                  :rules="formRules.required"
                  @change="handle_kanban_stage_change"
                />
              </v-col>
            </v-row>

            <!-- actions -->
            <v-row>
              <v-col>
                <v-btn
                  v-if="component_type == 'add'"
                  :color="formRules.valid ? 'primary' : ''"
                  @click="submitCreateDeal"
                >
                  Create Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="componentLoading"
                  />
                </v-btn>

                <v-btn
                  v-if="component_type == 'renew'"
                  :disabled="componentLoading || !formRules.valid"
                  color="primary"
                  @click="submitRenewDeal"
                >
                  Renew Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="componentLoading"
                  />
                </v-btn>

                <v-btn
                  v-if="component_type == 'edit'"
                  :disabled="componentLoading || !formRules.valid"
                  @click="submitUpdateDeal"
                  color="primary"
                >
                  Update Deal
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="componentLoading"
                  />
                </v-btn>

                <v-btn
                  class="ml-4"
                  v-if="component_type == 'edit' || component_type == 'renew'"
                  @click="
                    $router.push({
                      path: `/dashboard/admin/deal/read/${deal_id}`,
                    })
                  "
                >
                  Cancel
                </v-btn>

                <v-btn
                  class="ml-4"
                  v-if="component_type == 'add'"
                  @click="openCopyDealDialog = true"
                >
                  Copy Deal
                </v-btn>

                <v-btn
                  class="ml-4"
                  v-if="component_type == 'add'"
                  @click="$router.push({ path: `/dashboard/deal/list` })"
                >
                  Go To Deal List
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="make_a_choice_dialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-icon class="text-h4" color="orange darken-2"
                >mdi-alert-outline</v-icon
              >
              <span class="ml-5">Make a choice</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-5 text-h6">
          <v-row>
            <v-col class="ml-5 text-subtitle-1">
              <div>
                The item that you are trying to move has an existing record on
                this stage. The following can assist you in making the right
                choices:
              </div>

              <br />

              <div>
                <ul>
                  <li>
                    <b>KEEP RECORD</b>, if you want to use the existing record
                    for the objectives, tasks, helpers and notes.
                  </li>
                </ul>
              </div>

              <br />

              <div>
                <ul>
                  <li>
                    <b>CREATE NEW</b>: The existing record of the item for this
                    stage will be moved to the archive, and then a new record
                    will be created for objectives, tasks, and helpers.
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal lighten-1"
            text
            @click="
              choice = 'Keep Record';
              make_a_choice_dialog = false;
            "
          >
            Keep Record
          </v-btn>
          <v-btn
            color="teal lighten-1"
            text
            @click="
              choice = 'Archive and Create New';
              make_a_choice_dialog = false;
            "
          >
            Create New
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openCopyDealDialog" persistent hide-overlay fullscreen>
      <v-toolbar color="orange darken-3">
        <v-toolbar-title>
          <span>Copy Deal</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon title="Click to close" @click="openCopyDealDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <DealList view_type="create_deal" @dealId="handle_selected_deal"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from "@/plugins/API";
import DealList from "@/views/admin/deal/List.vue";

export default {
  name: "DealAdd",
  props: ["deal_id"],

  components: {
    DealList,
  },

  data() {
    return {
      error: false,
      errorMessage: "",
      submitted: false,

      openCopyDealDialog: false,

      component_type: "",
      kanban_stage_options: [],
      choice: "",
      make_a_choice_dialog: false,

      componentLoading: false,

      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },

      dealData: {
        public_name: "",
        internal_name: "",
        description: "",
        deal_purpose: "refinance",
        deal_type: "approval",
        province: "",
        status: "",
        kbn_stg: {
          id: null,
        },
      },
      deal_province_options: [
        { value: "AB", text: "Alberta" },
        { value: "BC", text: "British Columbia" },
        { value: "ON", text: "Ontario" },
      ],
      deal_purpose_options: [
        { value: "refinance", text: "Refinance" },
        { value: "purchase", text: "Purchase" },
        { value: "switch-transfer", text: "Switch/Transfer" },
      ],
      deal_type_options: [
        { value: "approval", text: "Approval" },
        { value: "preapproval", text: "Pre-Approval" },
      ],
    };
  },

  methods: {
    async submitCreateDeal() {
      try {
        this.componentLoading = true;

        this.$refs.form.validate();

        if (this.formRules.valid) {
          // console.log("data sent to createDeal: ", this.dealData);

          const response = await API().post(
            "/api/internal-admin/createDeal",
            this.dealData
          );

          console.log(response);

          if (response.status === 201) {
            this.submitted = true;

            // update kanban stage item
            const kanban_res = await API().post(
              "api/kanban/update_kanban_stage_item",
              {
                stage_id: this.dealData.kbn_stg.id,
                item_id: response.data.id,
                type: "Deal",
                choice: "Archive and Create New",
              }
            );

            // console.log("kanban_res: ", kanban_res);

            setTimeout(() => {
              this.componentLoading = false;
              this.$router.push(
                `/dashboard/admin/deal/read/${response.data.id}`
              );
            }, 3000);
          } else {
            this.componentLoading = false;
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
          }
        } else {
          alert("Please fill in all required fields.");
          this.componentLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.componentLoading = false;
      }
    },

    async submitUpdateDeal() {
      try {
        this.componentLoading = true;
        this.$refs.form.validate();

        if (this.formRules.valid) {
          const response = await API().patch(
            `/api/internal-admin/update_deal`,
            this.dealData
          );

          console.log(response);

          if (response.status === 201) {
            this.submitted = true;

            // update kanban stage item
            const kanban_res = await API().post(
              "api/kanban/update_kanban_stage_item",
              {
                stage_id: this.dealData.kbn_stg.id,
                item_id: response.data.id,
                type: "Deal",
                choice: this.choice,
              }
            );

            // console.log("kanban_res: ", kanban_res);

            setTimeout(() => {
              this.componentLoading = false;
              this.$router.push(
                `/dashboard/admin/deal/read/${response.data.id}`
              );
            }, 2000);
          } else {
            this.componentLoading = false;
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
          }
        } else {
          alert("Please fill in all required fields.");
          this.componentLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.componentLoading = false;
      }
    },

    async submitRenewDeal() {
      try {
        this.componentLoading = true;

        this.$refs.form.validate();

        if (this.formRules.valid) {
          const res = await API().post(
            `/api/internal-admin/deal/renew_deal`,
            this.dealData
          );

          this.componentLoading = false;

          console.log(res);

          // update kanban stage item
          const kanban_res = await API().post(
            "api/kanban/update_kanban_stage_item",
            {
              stage_id: this.dealData.kbn_stg.id,
              item_id: res.data.id,
              type: "Deal",
              choice: "Archive and Create New",
            }
          );

          // console.log("kanban_res: ", kanban_res.data);

          if (res.status === 201) {
            this.submitted = true;

            setTimeout(() => {
              this.$router.push(`/dashboard/admin/deal/read/${res.data.id}`);
            }, 3000);
          }
        } else {
          this.componentLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getKanbanStages() {
      try {
        const kanban_stages = await API().get(
          "api/kanban/get_kanban_deal_data"
        );

        kanban_stages.data.forEach((el) => {
          this.kanban_stage_options.push({
            value: el.id,
            text: el.stage_name,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getDealData() {
      try {
        this.componentLoading = true;
        const deal_data = await API().get(
          `api/internal-admin/dealRead/${this.deal_id}`
        );

        this.dealData = deal_data.data;

        if (this.component_type == "renew") {
          this.dealData.parent_deal = this.deal_id;
        }

        if (!this.dealData.kbn_stg) {
          this.dealData.kbn_stg = { id: null };
        }

        // console.log("dealData: ", this.dealData);

        this.componentLoading = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.componentLoading = false;
      }
    },

    async handle_kanban_stage_change() {
      try {
        if (this.component_type === "edit") {
          const res = await API().get(
            `api/kanban/read_deal_stage_item?deal_id=${this.deal_id}`
          );

          // check if a stage item already exist
          const existing = res.data.stg_itms.some(
            (item) => item.kbn_stg.id === this.dealData.kbn_stg.id
          );

          if (existing) {
            this.make_a_choice_dialog = true;
          }

          if (!existing) {
            this.choice = "Archive and Create New";
          }
        }
      } catch (error) {
        console.log(error);
        this.submitted = false;
        this.error = true;
        this.errorMessage = error;
      }
    },

    async handle_selected_deal(id) {
      try {
        const deal_data = await API().get(
          `api/internal-admin/dealRead/${id}`
        );

        this.dealData = deal_data.data;
        this.dealData.copy_deal = true;

        if (!this.dealData.kbn_stg) {
          this.dealData.kbn_stg = { id: null };
        }

        // console.log("dealData: ", this.dealData);

        this.openCopyDealDialog = false;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.openCopyDealDialog = false;
      }
    }
  },

  mounted() {
    if (this.$route.name == "deal_add") {
      this.component_type = "add";
      this.componentLoading = false;
    } else if (
      this.$route.name == "deal_edit" ||
      this.$route.name == "deal_renew"
    ) {
      switch (this.$route.name) {
        case "deal_edit":
          this.component_type = "edit";
          break;
        case "deal_renew":
          this.component_type = "renew";
          break;
      }
      this.getDealData();
    }

    this.getKanbanStages();
  },
};
</script>

<style></style>
